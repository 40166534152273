import * as React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';

export const Head = () => (
  <>
    <title>Dermapen - mezoterapia mikroigłowa w Olsztynie | Gabinet Elżbieta</title>
    <link rel="canonical" href="https://gabinetelzbieta.pl/oferta/dermapen/" />
  </>
);

const Main = styled.main`
  font-family: 'Lato', sans-serif;
`;

const Slider = styled.section`
  .gatsby-image-wrapper-constrained {
    width: 100%;
  }
`;

const MedycynaEstetyczna = ({ data }) => (
  <>
    <Slider>
      <GatsbyImage
        image={getImage(data.hero_4)}
        alt="Gabinet Elżbieta"
        loading="eager"
      />
    </Slider>

    <Main>
      <div className="row">
        <h1>Dermapen</h1>

        <p>
          Dermapen pobudza włókna kolagenu i&nbsp;elastyny. Oprócz tego powoduje, że dzięki nakłuciom, skóra jest gotowa na &quot;większe wchłonięcie&quot; specjalnie przygotowanych koncetratów. Zabieg ten powoduje dotlenienie skóry i&nbsp;poprawia krążenie.
        </p>

        <p>W pierwszej fazie nakłucia wzrasta powstawanie nowych włókien kolagenowych. W drugim etapie powstaje elastyna, proteoglikany, a&nbsp;także kolagen. W&nbsp;końcowej fazie zachodzą procesy, których efektem jest ujędrnięnie i&nbsp;zagęszczenie skóry.</p>

        <h2>Zalecenia do zabiegu</h2>

        <ul>
          <li>zmarszczki</li>
          <li>rozszerzone pory</li>
          <li>zwiotczała skóra</li>
        </ul>

        <h2>Przebieg zabiegu</h2>

        <p>Zabieg trwa około 60&nbsp;min. Do tego używamy jednorazowej, sterylnej głowicy. Głowica posiada kilkanaście cienkich igieł. Głębokość nakłuć jest między 0,25&nbsp;mm a 2,5&nbsp;mm. Głębokość regulawana jest przez kosmetologa. Po nakłuciu nakładamy skoncetrowane ampułki.</p>

        <p>W zależności od problemu, indwywidualnie dobieramy odpowiedni preparat. Po zabiegu skóra jest zaczerwieniona.</p>

        <h2>Po zabiegu</h2>

        <ul>
          <li>zakaz opalania się</li>
          <li>skórę dobrze nawilżamy</li>
          <li>stosujemy kremy z filtrami UV</li>
        </ul>

        <h2>Przeciwwskazania</h2>

        <ul>
          <li>świeża opalenizna</li>
          <li>poparzenia</li>
          <li>stany zapalne na skórze</li>
          <li>ciąża i&nbsp;karmienie piersią</li>
          <li>choroby nowotworowe</li>
          <li>problem z&nbsp;krzepnięciem krwi</li>
        </ul>

        <p>
          Po zabiegu zmarszczyki maleją, a&nbsp;kontur twarzy, szyi i&nbsp;dekoltu sie poprawia. Aby uzyskać dobry efekt należy zrobić serię około 5&nbsp;zabiegów co 3 tygodnie.
        </p>

        <h2>Cena: od 200 zł.</h2>
      </div>
    </Main>
  </>
);

export const query = graphql`
  query {
    hero_4: file(relativePath: {regex: "/hero\/4.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 2000
        )
      }
    }
  }
`;

export default MedycynaEstetyczna;
